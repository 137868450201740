<template>
  <!-- 关于我们-联系我们 -->
  <div class="mt-nav bgcolor-white">
    <static-banner :image_banner="image_banner" main-title="联系我们"
                   sub-title="对业务、技术、招聘、园区入驻等有任何问题，请通过这些渠道与我们联系"/>

    <div class="center-content">
      <horizontal-text content="联系我们" class="pt-4r"/>
      <el-row class="pl-4r">
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" class="pt-2r">
          <div class="content-title-lg">长春斯纳欧软件有限公司</div>
          <div class="content">
            地址：吉林省长春市高新区顺达路333号<br/>
            邮编：130103<br/>
            电话：+86-431-87023008 按0<br/>
            传真：+86-431-87023008 按8016（分机）<br/>
            邮箱：sunao@it-sunao.com
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" class="pt-2r">
          <div class="content-title-sm">商务及客户服务</div>
          <div class="content">
            电话：+86-431-87023008 按8008（分机）<br/>
            邮箱：chaihua@it-sunao.com
          </div>
        </el-col>
      </el-row>
      <el-row class="pl-4r">
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" class="pt-2r">
          <div class="content-title-sm">招聘</div>
          <div class="content">
            电话：+86-431-81100982<br/>
            邮箱：sunao_hr@it-sunao.com
          </div>
        </el-col>
        <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" class="pt-2r">
          <div class="content-title-sm">园区招商</div>
          <div class="content">
            联系人：柴经理<br/>
            移动电话：178 4312 4000
          </div>
        </el-col>
      </el-row>
      <el-image :src="image_location" fit="cover" class="pt-2r" style="height: 31.25rem;"/>
      <div class="pt-4r"></div>
    </div>
  </div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "ContactUs",
  components: {HorizontalText, StaticBanner},
  data() {
    return {
      image_banner: require('@/assets/images/banner-contact-us.jpg'),
      image_location: require('@/assets/images/联系我们.png'),
    }
  }
}
</script>

<style scoped>
.content-title-lg {
  font-size: 1.5rem;
  font-weight: 800;
  padding-bottom: 1.5rem;
}

.content-title-sm {
  font-size: 1.25rem;
  font-weight: 800;
  padding-bottom: 1.5rem;
}

.content {
  font-size: 1.125rem;
  line-height: 2.2rem;
}

.pl-4r {
  padding-left: 4rem;
}
</style>