<template>
  <div class="news-content center-content">
    <el-row type="flex" align="middle" class="pt-4r">
      <el-col :span="12">
        <!-- 企业资讯 -->
        <HorizontalText :content="contentTitle" :line-index="0"/>
      </el-col>
      <el-col :span="12" class="flex-row-end">
        <el-input placeholder="检索标题" v-model="queryParams.newsTitle" clearable
                  @keyup.enter.native="handleQuery" style="width: 20rem;">
          <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
        </el-input>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="pt-3r">
      <div v-for="(item, index) in news" :key="index">
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="news-container mb-20">
            <InformationCom :news="item"/>
          </div>
        </el-col>
      </div>
    </el-row>
    <el-row class="pt-3r">
      <div class="flex-column-center">
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="queryParams.pageNum"
            :page-size.sync="queryParams.pageSize"
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
  </div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import InformationCom from "@/components/InformationCom";
import {listNews} from "@/api/news";

export default {
  name: "NewsContent",
  components: {
    HorizontalText,
    InformationCom
  },
  props: {
    newsType: String,
    contentTitle: String
  },
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 12,
        newsType: '', // 1企业资讯 2媒体报道 3企业公告 4园区资讯
        newsTitle: ''
      },
      total: 0,
      news: []
    }
  },
  mounted() {
    this.queryParams.newsType = this.newsType
    this.handleQuery()
  },
  methods: {
    handleQuery() {
      listNews(this.queryParams).then(res => {
        this.news = res.rows
        this.total = res.total
      })
    },
    handleCurrentChange() {
      this.handleQuery()
      this.toTop()
    },
    toTop() {
      document.documentElement.scrollTop = 500;
    },
  }
}
</script>

<style scoped>
/*.news-content {*/
/*  width: 95.1rem;*/
/*  margin: 0 auto;*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*  .news-content {*/
/*    width: 90%;*/
/*  }*/
/*}*/

.el-input {
  font-size: 0.875rem;
}

/deep/ .el-input__inner {
  border-radius: 0.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  padding: 0 0.9375rem;
}

/deep/ .el-input-group__append {
  padding: 0 1.25rem;
}

.el-pagination {
  color: #222;
}

/deep/ .el-pagination button, /deep/ .el-pagination button:disabled, /deep/ .el-pager li {
  background-color: #f2f2f2;
}

/deep/ .el-pagination .btn-next .el-icon, /deep/ .el-pagination .btn-prev .el-icon {
  font-size: 1rem;
}

/deep/ .el-pager li.active {
  color: #2C9FD0;
}

/deep/ .el-pager li:hover {
  color: #2C9FD0;
}

/deep/ .el-pager li {
  font-size: 1rem;
}

/deep/ .el-pagination .btn-next, /deep/ .el-pagination .btn-prev {
  color: #222;
}

/deep/ .el-pagination button:disabled {
  color: #C0C4CC;
}

/deep/ .el-pagination .btn-next:hover, /deep/ .el-pagination .btn-prev:hover {
  color: #2C9FD0;
}
</style>