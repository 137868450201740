<template>
  <!--咨询详情-->
  <div class="information-detail mt-nav bgcolor-white">
    <div class="center-content">
      <div class="title-container">
        <div class="news-detail-title mb-20">
          {{ info.newsTitle }}
        </div>
        <div class="news-detail-date">
          {{ parseTime(info.publishTime, 'yyyy-m-d') }}
        </div>
        <div class="news-source mt-20 text-left" v-if="info.newsSource">
          来源：{{ info.newsSource }}
        </div>
      </div>
      <div class="news-detail-content pb-20">
        <div class="ql-editor" v-html="info.newsContent"></div>
      </div>

      <div class="font-18 text-left pb-20" v-if="prev.newsId || next.newsId">
        <div class="prev-news mb-5" v-if="prev.newsId">
          <router-link
              :to="{name:newsType?'InformationDetail':'HomeNewsDetail',params:{id: prev.newsId, newsType: newsType}}">
            <span class="font-weight-700">上一条：</span>{{ prev.newsTitle }}
          </router-link>
        </div>
        <div class="next-news" v-if="next.newsId">
          <router-link
              :to="{name:newsType?'InformationDetail':'HomeNewsDetail',params:{id: next.newsId, newsType: newsType}}">
            <span class="font-weight-700">下一条：</span>{{ next.newsTitle }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getNewsDetail, listNews} from "@/api/news";
import config from "@/utils/config";

export default {
  name: "InformationDetail",
  props: {
    id: [Number, String],
    newsType: String
  },
  data() {
    return {
      info: {},
      prev: {},
      next: {},
      curIndex: 0
    }
  },
  mounted() {
    this.getDetail(this.id)
    this.getPrevNext(this.id, this.newsType)
  },
  methods: {
    getDetail(newsId) {
      getNewsDetail(newsId).then(res => {
        if (res && res.data) {
          this.info = res.data
        } else {
          this.$router.push({path: '/'})
        }
      })
    },
    getPrevNext(newsId, newsType) {
      let queryParams = {
        pageNum: 1,
        pageSize: 99999,
        newsType: newsType
      }
      listNews(queryParams).then(res => {
        const news = res.rows
        if (news.length > 0) {
          const filterData = news.filter((item, index) => {
            // 通过路径刷新newsId为String，故用==判断
            if (newsId == item.newsId) {
              this.curIndex = index
              return item
            }
          });
          if (this.curIndex > 0) {
            this.prev = news[this.curIndex - 1]
          }
          if (this.curIndex < news.length - 1) {
            this.next = news[this.curIndex + 1]
          }
        }
      })
    },
  }
}
</script>

<style scoped>
/*引入富文本的样式*/
@import "@/assets/style/quill/quill.core.css";
@import "@/assets/style/quill/quill.bubble.css";
@import "@/assets/style/quill/quill.snow.css";

.title-container {
  padding: 1.875rem 0;
}

a {
  color: #222222;
}

a:-webkit-any-link {
  cursor: pointer;
}

a:link {
  text-decoration: none;
}

a:hover {
  color: #2c9fd0;
  text-decoration: underline;
}

.news-source {
  font-size: 0.875rem;
  color: #333;
}

.news-detail-content {
  font-size: 1.125rem;
  line-height: 2.25rem;
}

.news-detail-title {
  font-size: 2.25rem;
  font-weight: 700;
  text-align: center;
}

.news-detail-date {
  font-size: 1.125rem;
  font-weight: 700;
  text-align: center;
}

.ql-editor {
  line-height: 2.25rem;
}

/deep/ .ql-editor img {
  width: 100% !important;
  height: 100% !important;
  display: block !important;
  margin: 1rem auto !important;
}

@media screen and (max-width: 768px) {
  .news-source {
    font-size: 14px;
  }

  .font-18 {
    font-size: 14px;
  }
}
</style>