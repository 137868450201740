import axios from 'axios'
import config from "@/utils/config";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: "/portal",
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
      const code = res.data.code
      if (code === 401) {
        // MessageBox.confirm(
        //     '登录状态已过期，您可以继续留在该页面，或者重新登录',
        //     '系统提示',
        //     {
        //         confirmButtonText: '重新登录',
        //         cancelButtonText: '取消',
        //         type: 'warning'
        //     }
        // ).then(() => {
        //     store.dispatch('LogOut').then(() => {
        //         location.reload() // 为了重新实例化vue-router对象 避免bug
        //     })
        // })
      } else if (code !== 200) {
        // Notification.error({
        //     title: res.data.msg
        // })
        // return Promise.reject('error')
      } else {
        return res.data
      }
    },
    error => {
      console.log('err' + error)
      // Message({
      //     message: error.message,
      //     type: 'error',
      //     duration: 5 * 1000
      // })
      return Promise.reject(error)
    }
)

export default service
