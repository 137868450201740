<template>
  <!-- 关于我们-里程碑 -->
  <div class="mt-nav bgcolor-white">
    <static-banner :image_banner="image_banner" main-title="只管风雨兼程，终将破茧成蝶"/>

    <div class="center-content pt-4r">
      <horizontal-text content="里程碑"/>
      <div class="pt-4r">
        <el-timeline :reverse="true">
          <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :icon="activity.icon"
              :type="activity.type"
              :color="activity.color"
              size="large"
              :timestamp="activity.timestamp"
              placement="top">
            <el-card :body-style="{fontSize: '1.125rem', lineHeight: '2.2rem', borderRadius: '8px'}">
              <div v-html="activity.content"></div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div class="pt-4r"></div>
    </div>
  </div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "Milestone",
  components: {HorizontalText, StaticBanner},
  data() {
    return {
      image_banner: require('@/assets/images/banner-milestone.jpg'),
      activities: [{
        content: '<p>斯纳欧软件成立</p>',
        timestamp: '2011',
        color: '#d80878',
        icon: 'el-icon-s-opportunity'
      }, {
        content: '<p>斯纳欧培训学校成立</p><p>联迪恒星（南京）入股</p>',
        timestamp: '2012',
        color: '#70224c',
        icon: 'el-icon-share'
      }, {
        content: '<p>取得CMMI3级认证</p><p>取得ISO9001、ISO27001认证</p>',
        timestamp: '2013',
        color: '#f8c706',
        icon: 'el-icon-s-flag'
      }, {
        content: '<p>与松下电器软件开发（大连）、日冲信息（大连）建立战略合作关系</p>',
        timestamp: '2014',
        color: '#235ed4',
        icon: 'el-icon-star-on'
      }, {
        content: '<p>与NTT DATA MSE大连有限公司建立战略合作关系</p>',
        timestamp: '2015',
        size: 'large',
        color: '#df2405',
        icon: 'el-icon-video-camera-solid'
      }, {
        content: '<p>取得CMMI5级认证</p><p>承建运营“长春高新国际服务外包产业园”</p>',
        timestamp: '2016',
        color: '#d69d1c',
        icon: 'el-icon-message-solid'
      }, {
        content: '<p>取得“吉林省科技小巨人企业”称号</p><p>取得“国家高新技术企业”称号</p><p>取得“长春市企业技术中心”称号</p><p>大连分公司成立</p>',
        timestamp: '2017',
        color: '#89c557',
        icon: 'el-icon-s-promotion'
      }, {
        content: '<p>承建运营“长春中日智能制造产业园”</p>',
        timestamp: '2018',
        color: '#891791',
        icon: 'el-icon-s-open'
      }, {
        content: '<p>荣获“全国模范劳动关系和谐企业”称号</p>',
        timestamp: '2019',
        color: '#fff001',
        icon: 'el-icon-location'
      }, {
        content: '<p>北京分公司成立</p>',
        timestamp: '2021',
        color: '#cf3d3d',
        icon: 'el-icon-s-check'
      }]
    }
  }
}
</script>

<style scoped>
/deep/ .el-timeline-item__node--large {
  left: -0.7rem;
  width: 2rem;
  height: 2rem;
}

/deep/ .el-timeline-item__timestamp {
  font-size: 3.125rem;
  color: #333;
}

/deep/ .el-timeline-item__wrapper {
  padding-left: 2rem;
}

/deep/ .el-timeline-item__icon {
  font-size: 1.125rem;
}
</style>