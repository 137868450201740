import Vue from 'vue';

import {
    Row,
    Col,
    Image,
    Divider,
    Collapse,
    CollapseItem,
    Menu,
    MenuItem,
    Submenu,
    Input,
    Button,
    Pagination,
    Carousel,
    CarouselItem,
    Timeline,
    TimelineItem,
    Card
} from 'element-ui'

Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(Divider)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Input)
Vue.use(Button)
Vue.use(Pagination)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Timeline)
Vue.use(TimelineItem)
Vue.use(Card)
