<template>
  <!--导航栏-->
  <div class="navbar">
    <el-row class="container" type="flex">
      <el-col :span="24" class="flex-row nav-header-content">
        <a href="/">
          <el-image :src="require('@/assets/images/sunao.png')" fit="cover" class="logo"></el-image>
        </a>
        <el-menu mode="horizontal" router class="hidden-sm-and-down">
          <el-submenu index="1" :show-timeout="200">
            <template slot="title">关于我们</template>
            <el-menu-item index="/enterpriseIntro">企业介绍</el-menu-item>
            <el-menu-item index="/corporateCulture">企业文化</el-menu-item>
            <el-menu-item index="/milestone">里程碑</el-menu-item>
            <el-menu-item index="/qualification">资质荣誉</el-menu-item>
            <el-menu-item index="/contactUs">联系我们</el-menu-item>
          </el-submenu>
          <el-submenu index="2" :show-timeout="200">
            <template slot="title">媒体中心</template>
            <el-menu-item index="/corporate-information">企业资讯</el-menu-item>
            <el-menu-item index="/media-coverage">媒体报道</el-menu-item>
            <el-menu-item index="/corporate-notice">企业公告</el-menu-item>
          </el-submenu>
          <el-menu-item index="/service">服务</el-menu-item>
          <el-submenu index="4" :show-timeout="200">
            <template slot="title">产业园</template>
            <el-menu-item index="/aboutPark">关于产业园</el-menu-item>
            <el-menu-item index="/park-information">园区资讯</el-menu-item>
          </el-submenu>
          <el-menu-item index="/career">职业机会</el-menu-item>
        </el-menu>
        <!-- 移动端菜单 -->
        <div :class="{collapsed: !showMobileMenu}" class="navbar-toggle hidden-md-and-up flex-row-center"
             @click="showMobileMenu = !showMobileMenu">
          <div>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </div>
        </div>
        <div class="hidden-sm-and-down"></div>
      </el-col>
    </el-row>
    <div class="mobile-menu-box hidden-md-and-up" v-if="showMobileMenu">
      <el-menu router unique-opened @select="handleSelect">
        <el-submenu index="1">
          <template slot="title">关于我们</template>
          <el-menu-item index="/enterpriseIntro">企业介绍</el-menu-item>
          <el-menu-item index="/corporateCulture">企业文化</el-menu-item>
          <el-menu-item index="/milestone">里程碑</el-menu-item>
          <el-menu-item index="/qualification">资质荣誉</el-menu-item>
          <el-menu-item index="/contactUs">联系我们</el-menu-item>
        </el-submenu>
        <el-submenu index="2">
          <template slot="title">媒体中心</template>
          <el-menu-item index="/corporate-information">企业资讯</el-menu-item>
          <el-menu-item index="/media-coverage">媒体报道</el-menu-item>
          <el-menu-item index="/corporate-notice">企业公告</el-menu-item>
        </el-submenu>
        <el-menu-item index="/service">服务</el-menu-item>
        <el-submenu index="4">
          <template slot="title">产业园</template>
          <el-menu-item index="/aboutPark">关于产业园</el-menu-item>
          <el-menu-item index="/park-information">园区资讯</el-menu-item>
        </el-submenu>
        <el-menu-item index="/career">职业机会</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      showMobileMenu: false
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      this.showMobileMenu = false
    }
  }
}
</script>

<style scoped>
@import "@/assets/style/navbar.css";
</style>