<template>
  <!-- 关于我们-企业文化 -->
  <div class="mt-nav bgcolor-white">
    <static-banner :image_banner="image_banner" main-title="百年理想，合作共赢"
                   sub-title="“共同成长，持续改进”是斯纳欧的文化核心"/>

    <div class="center-content text-center">
      <!-- 我们的使命 -->
      <horizontal-text content="我们的使命" :line-index="2" class="pt-4r"/>
      <div class="pt-2r base-content">
        以专业的信息化解决方案和服务为核心，通过行业融合和创新改变社会生活。
      </div>
      <el-image :src="corporate_culture_1" fit="cover" class="pt-2r"/>

      <!-- 我们的愿景 -->
      <horizontal-text content="我们的愿景" :line-index="2" class="pt-2r"/>
      <div class="pt-2r base-content">
        通过组织过程的持续改进、领导力与员工竞争力发展、联盟和开放式创新，成为一流的信息技术解决方案与服务供应商。
      </div>
      <el-image :src="corporate_culture_2" fit="cover" class="pt-2r"/>

      <!-- 价值观 -->
      <horizontal-text content="价值观" :line-index="1" class="pt-2r"/>
      <div class="pt-2r base-content">
        全员参与持续改进，满足客户需求，提升客户价值<br/>
        人力增值的目标优先于财务增值的目标<br/>
        通过全周期人才培养，实现高能高薪，公司和员工共同成长<br/>
        积极为伙伴提供市场、管理、技术支持<br/>
        公司的利润在股东、员工、合作公司间以合理的方式分享<br/>
        充分考虑员工的生活品质，为员工家庭提供有力的支撑
      </div>
      <el-image :src="corporate_culture_3" fit="cover" class="pt-2r"/>
      <div class="pt-4r"></div>
    </div>
  </div>
</template>

<script>
import HorizontalText from "@/components/HorizontalText";
import StaticBanner from "@/components/StaticBanner";

export default {
  name: "CorporateCulture",
  components: {HorizontalText, StaticBanner},
  data() {
    return {
      image_banner: require('@/assets/images/banner-corporate-culture.jpg'),
      corporate_culture_1: require('@/assets/images/corporate-culture-1.jpg'),
      corporate_culture_2: require('@/assets/images/corporate-culture-2.jpg'),
      corporate_culture_3: require('@/assets/images/corporate-culture-3.jpg'),
    }
  }
}
</script>

<style scoped>
.base-content {
  font-size: 1.125rem;
  line-height: 2.2rem;
}
</style>