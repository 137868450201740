import request from "@/utils/request";

// 查询资讯管理列表
export function listNews(query) {
    const params = {
        ...query,
        orderByColumn: 'publishTime',
        isAsc: 'descending',
        isSubmit: 'Y'
    }
    return request({
        url: '/base/news/platform/list',
        method: 'get',
        params: params
    })
}

// 查询资讯管理详细
export function getNewsDetail(newsId) {
    return request({
        url: '/base/news/platform/' + newsId,
        method: 'get'
    })
}