import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import routers from './routers'
import '@/plugins/element'
import '@/assets/style/index.css'
import '@/assets/style/iconfont/iconfont.css'
import {parseTime} from "@/utils/sunao";

Vue.prototype.parseTime = parseTime
Vue.use(VueRouter)

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
const router = new VueRouter({
    // mode: 'hash',
    mode: 'history',
    scrollBehavior: () => ({y: 0}),
    routes: routers
})

router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) {  //如果未匹配到路由
        from.name ? next({name: from.name}) : next('/');   //如果上级也未匹配到路由则跳转首页，如果上级能匹配到则转上级路由
    } else {
        next();    //如果匹配到正确跳转
    }
})

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
