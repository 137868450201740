<template>
  <!--关注我们-->
  <div class="center-content">
    <div class="follow-us">
      <div class="flex-row-end">
        <span class="follow-us-text">关注我们</span>
        <div class="icon-box">
          <i class="iconfont el-icon-weixin" @click="isShow = !isShow">
            <div class="wechat-qrcode" :class="{show:isShow}">
              <button class="js-wechat-share-close-btn share-close" data-target=".share-open">×</button>
              <h4>官方微信</h4>
              <div class="qrcode">
                <el-image fit="fill" :src="require('@/assets/images/sunao-wechat.jpg')"></el-image>
                <p>微信中长按识别二维码或搜索“斯纳欧软件”公众号</p>
              </div>
            </div>
          </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FollowUs",
  data() {
    return {
      isShow: false
    }
  }
}
</script>

<style scoped>

.follow-us {
  padding: 1.25rem 0;
}

.follow-us > div {
  height: 3rem;
}

.follow-us-text {
  font-size: 1.25rem;
  font-weight: 500;
}

.el-icon-weixin {
  font-size: 1.5rem;
  margin: 0 1rem;
}

.icon-box i {
  text-decoration: none;
  position: relative;
}

.icon-box i:hover {
  cursor: pointer;
}

.wechat-qrcode.show {
  display: block;
}

.wechat-qrcode {
  border: 1px solid #eee;
  background-color: #fff;
  box-shadow: 0 2px 10px #aaa;
}

.wechat-qrcode {
  position: absolute;
  z-index: 1999;
  top: -206px;
  left: -99px;
  width: 210px;
  height: 186px;
  color: #666;
  font-size: 12px;
  text-align: center;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  display: none;
}

.show {
  display: block !important;
}

.wechat-qrcode button, .wechat-qrcode .share-close {
  background: transparent;
  border: 0;
  font-size: 25px;
  position: absolute;
  top: -6px;
  right: 2px;
  color: #000 !important;
}

button {
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  cursor: pointer;
}

.wechat-qrcode .qrcode {
  width: 144px;
  margin: 5px auto;
}

.wechat-qrcode img {
  width: 100%;
  vertical-align: middle;
  display: block;
  border-style: none;
  max-width: 100%;
}

p {
  margin: 0;
  color: #666;
  font-size: 1em;
  line-height: 1.95em;
}

.wechat-qrcode h4 {
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  background-color: #f3f3f3;
  margin: 0;
  padding: 0;
  color: #777;
  font-weight: bold;
}

.wechat-qrcode .qrcode p {
  display: none;
}

@media screen and (max-width: 992px) {
  .wechat-qrcode .qrcode p {
    display: block;
  }

  .follow-us .flex-row-end {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .wechat-qrcode {
    top: -256px;
    height: 242px;
  }
}
</style>